<template>
  <div class="row-item shipment-alert-collapse">
    <el-collapse-item
      :name="item.shipmentAlertId"
      class="hidden-arrow">
      <div
        slot="title"
        class="single-column-item">
        <div class="flex-item ellipses text-transform-none">
          {{ item.name }}
          <sub>Alert Name</sub>
        </div>
        <div class="flex-item view-type email">
          <div
            v-click-outside="save"
            v-if="isEditing"
            class="edit-item">
            <el-tooltip
              :value="!!error"
              :manual="true"
              placement="bottom"
              popper-class="is-light">
              <template slot="content">
                <div class="popover-tooltip-notification small-content">
                  <h3>Invalid input</h3>
                  <div class="flex-container">
                    <i class="error-icon" />
                    <p>{{ error }}</p>
                  </div>
                </div>
              </template>
              <input
                ref="input"
                v-model="email"
                :class="{ error: error}"
                type="email"
                name=""
                class="full-width"
                @keyup.enter.stop="save"
                @keyup.escape.stop="cancel"
                @keyup.space.stop>
            </el-tooltip>
            <span
              class="removed-icon"
              @click.stop="cancel" />
          </div>
          <span v-else>{{ item.emailAddress }}</span>
          <i
            v-if="!isEditing"
            class="edit-icon custom-edit-icon"
            @click.stop="editMode" />
          <sub>Email Address</sub>
        </div>
        <div class="flex-item view-type">
          {{ item.createdOn | formatDate }}
          <sub>Date Created</sub>
        </div>
        <div class="flex-item view-type">
          {{ item.totalAlerts }}
          <sub>Email alerts sent</sub>
        </div>
        <div class="flex-item view-type">
          <span v-if="item.totalAlerts">{{ item.lastAlertOn | formatDate }}</span>
          <span v-else>n/a</span>
          <sub>Last alert</sub>
        </div>
      </div>
      <search-keyword-bar
        :fields="fields"
        :search-keywords="item.criteria.keywords" />

      <div>
        <div class="criteria-buttons">
          <img
            v-if="item.country"
            :src="require(`~/assets/img/flag/${item.country}.svg`)"
            :alt="item.country"
            class="flag">
          <label
            v-if="item.country">{{ item.country | upperCase }} {{ item.shipmentType | startCase }} Dataset</label>
          <div class="option-buttons flex-container justify-right">
            <el-popover
              ref="alertPopover"
              placement="bottom-end"
              popper-class="pre-delete-popover open with-footer">
              <div class="popover-content">
                <div class="pop-over-default">Are you sure you want to delete <strong>{{ item.name }}</strong> email alert?</div>
              </div>
              <div class="popover-footer flex-container justify-right">
                <a
                  class="btn btn-text secondary"
                  @click="closePopover">NO</a>
                <a
                  class="btn btn-text"
                  @click="deleteAlert">YES</a>
              </div>
              <a
                slot="reference"
                class="btn btn-text secondary">Delete {{ item.name }}</a>
            </el-popover>
            <button
              v-if="researchable"
              class="btn btn-primary"
              @click="rerunSearch">
              Re-run search
            </button>
            <el-popover
              v-else
              ref="rerunSearch"
              placement="bottom-end"
              trigger="hover"
              popper-class="pre-delete-popover open with-footer">
              <div class="popover-tooltip-notification">
                <h3>Upgrade</h3>
                <div class="flex-container">
                  <i class="database-upgrade-icon large-icon" />
                  <p>
                    <a
                      href="#"
                      class="inline-normal"
                      @click="showUpgrade">Upgrade</a> your subscription to run this search.</p>
                </div>
              </div>
              <button
                slot="reference"
                class="btn btn-disable btn-primary">
                Re-run search
              </button>
            </el-popover>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { upperCase, startCase } from 'lodash'
import { ValidationProvider } from 'vee-validate'
import { updateKeywordStructure } from '~/utils/advancedSearch'
import SearchKeywordBar from '~/components/search/SearchKeywordBar'
import validator from 'validator'

export default {
  name: 'ShipmentAlertCollapse',
  components: {
    ValidationProvider,
    SearchKeywordBar
  },
  filters: {
    upperCase,
    startCase,
    formatDate(val) {
      /**
       * Format 'en-CA' with dateStyle 'short' is 'yyyy-MM-dd'
       */
      return new Date(val * 1000).toLocaleString('en-CA', {
        dateStyle: 'short'
      })
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    fields: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isEditing: false,
      email: ''
    }
  },
  computed: {
    ...mapState(['maxDate']),
    ...mapGetters(['currentSearchURL']),
    ...mapGetters('userCredits', ['searchCredits']),
    researchable() {
      return (
        this.searchCredits &&
        this.validateSearchFields(
          updateKeywordStructure(this.item.criteria.keywords)
        )
      )
    },
    error() {
      if (!this.email.trim()) return 'Email address cannot be empty.'
      if (!validator.isEmail(this.email.trim())) return 'Invalid email address'

      return ''
    }
  },
  methods: {
    ...mapMutations('search', ['setSearchKeywords', 'setMiscFilters']),
    ...mapMutations('userSubscriptions', ['setOpenUpgradeModal']),
    ...mapActions('search', [
      'updateShipmentAlert',
      'deleteShipmentAlert',
      'setDateRange',
      'search'
    ]),
    validateSearchFields(criteria) {
      if (criteria.field) return this.fields[criteria.field].searchable

      let invalidField = false
      criteria.i.forEach(item => {
        invalidField = invalidField || !this.validateSearchFields(item)
      })

      return !invalidField
    },
    editMode() {
      this.isEditing = true
      this.email = this.item.emailAddress

      this.$nextTick(() => {
        this.$refs.input.focus()
        this.$refs.input.select()
      })
    },
    closePopover() {
      this.$refs.alertPopover.doClose()
    },
    cancel() {
      this.isEditing = false
    },
    save() {
      this.isEditing = false

      if (this.error || this.email.trim() === this.item.emailAddress.trim())
        return

      this.updateShipmentAlert({
        id: this.item.shipmentAlertId,
        emailAddress: this.email
      })
    },
    deleteAlert() {
      this.closePopover()
      this.deleteShipmentAlert(this.item.shipmentAlertId)
      this.$emit('delete')
    },
    rerunSearch() {
      const { keywords, misc } = this.item.criteria
      const arrivalDates = {
        from: this.getLast3MonthsTimestamp(),
        to: this.maxDate
      }

      this.setSearchKeywords(keywords)
      this.setMiscFilters(misc)
      this.setDateRange(arrivalDates)

      this.search().then(() => this.$router.push(this.currentSearchURL))
    },
    getLast3MonthsTimestamp() {
      const today = this.$date.getUtcDate(this.maxDate)
      return this.$date.subMonths(today, 3).getTime()
    },
    showUpgrade() {
      this.$refs.rerunSearch.$refs.popper.style.display = 'none'
      this.setOpenUpgradeModal(true)
    }
  }
}
</script>

<style src="~/assets/scss/sections/searchbar-criteria/search-bar-criteria.scss" lang="scss"/>
