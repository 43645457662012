<template>
  <div
    class="search-bar-criteria">
    <ul
      ref="searchCriteria">
      <li
        v-for="(searchKeyword, index) in searchCriteria"
        :key="index"
        class="card-base">
        <h4>{{ fields[searchKeyword.field].displayName }}</h4>
        <div class="criteria-section">
          <ul class="criteria-group">
            <li
              v-for="(keyword, index) in searchKeyword.keyword"
              :key="index"
              class="cursor-auto">
              {{ keyword | formatKeyword(searchKeyword.type) }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  name: 'SearchKeywordBar',
  props: {
    fields: {
      type: Object,
      required: true
    },
    searchKeywords: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      keywords: [],
      updateKeywords: [],
      isOverflow: false,
      identifiers: {},
      controlGroups: [
        { text: 'New Search', icon: 'zoom-icon', newSearch: true },
        { text: 'Edit Search', icon: 'edit-icon', newSearch: false },
        { text: 'Clear All', icon: 'removed-icon', clearAll: true }
      ]
    }
  },
  computed: {
    popperCriteria() {
      return 'popper-criteria'
    },
    hasKeywords() {
      return Object.keys(this.searchKeywords).length > 0
    },
    searchCriteria() {
      if (this.hasKeywords)
        return this.getSearchCriteria(cloneDeep(this.searchKeywords))
      return []
    },
    isOverflowing() {
      return this.overflowContent()
    }
  },
  watch: {
    searchKeywords: {
      handler() {
        this.keywords = []
      },
      deep: true
    }
  },
  methods: {
    overflowContent() {
      this.$nextTick(() => {
        const element = this.$refs.searchCriteria

        this.isOverflow =
          (element ? element.scrollWidth : null) >
          (element ? element.offsetWidth : null)
      })
      return this.isOverflow
    },
    getSearchCriteria(keywords) {
      this.identifiers = this.getGroupCriteria(keywords)
      return this.keywords
    },
    getGroupCriteria(condition) {
      const keywordsArray = condition.i
      const type = 'condition'

      const ids = keywordsArray.map(condition => {
        if (condition.i) {
          return this.getGroupCriteria(condition)
        } else {
          return this.getKeyword(condition)
        }
      })

      return { type, ids }
    },
    getKeyword(condition) {
      const identifier = {
        type: 'keyword',
        id: condition.id,
        field: condition.field,
        keyword: condition.keyword
      }

      this.keywords.push(identifier)
      return identifier
    }
  }
}
</script>
<style src="~/assets/scss/sections/searchbar-criteria/search-bar-criteria.scss" lang="scss"/>
