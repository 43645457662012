<template>
  <div>
    <breadcrumb current-page="Shipment Alerts"/>
    <div class="shipment-alerts">
      <div class="card-base">
        <div class="card-head-bar">
          <div class="head-title">
            <h1>Shipment Alerts</h1>
            <sub>Customize your automated email shipment alerts</sub>
          </div>
          <h3>Alerts Remaining: {{ emailAlertsCredits }}</h3>
        </div>

        <div class="card-main">
          <section
            v-for="item in shipmentAlerts"
            :key="item.shipmentAlertId">
            <el-collapse
              :value="shipmentAlertsCollapse"
              class="plain"
              @change="handleChange">
              <shipment-alert-collapse
                :fields="fields"
                :item="item"
                @delete="handleChange" />
            </el-collapse>
          </section>
          <div
            v-if="!shipmentAlerts.length && !$actions.pending('search/fetchShipmentAlerts')">
            <div class="empty-state">
              <empty-state-notification title="No Shipment Alerts available."/>
              <p>Try adding some shipment alerts.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Breadcrumb from '~/components/common/Breadcrumb'
import hideSidebarMixin from '~/mixins/hideSidebar'
import ShipmentAlertCollapse from '~/components/collapse-items/ShipmentAlertCollapse'
import EmptyStateNotification from '~/components/common/EmptyStateNotification'

export default {
  name: 'ShipmentAlerts',
  head() {
    return {
      title: 'Shipment Alerts - ' + ISNG_TITLE /*eslint-disable-line no-undef */
    }
  },
  components: {
    Breadcrumb,
    ShipmentAlertCollapse,
    EmptyStateNotification
  },
  mixins: [hideSidebarMixin],
  data() {
    return {
      shipmentAlertsCollapse: []
    }
  },
  computed: {
    ...mapState('search', ['shipmentAlerts']),
    ...mapState('views', ['fields']),
    ...mapGetters('userCredits', ['emailAlertsCredits'])
  },
  mounted() {
    this.shipmentAlertsCollapse = this.shipmentAlerts.map(
      alert => alert.shipmentAlertId
    )
  },
  methods: {
    handleChange() {
      this.shipmentAlertsCollapse = this.shipmentAlerts.map(
        alert => alert.shipmentAlertId
      )
    }
  },
  async fetch({ store, route }) {
    const promise = store.dispatch('search/initialize', route)

    if (!store.state.isMounted) {
      await promise
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/sections/breadcrumbs.scss"/>
<style lang="scss" src="~/assets/scss/sections/download/download-history.scss" />
<style lang="scss" src="~/assets/scss/components/data-viewer/data-collapse-viewer.scss" />
